<script>
	import CircleCheckIcon from '$lib/components/Icons/CircleCheckIcon.svelte';
	import { onMount } from 'svelte';
	export let choices = [];
	export let value = 0;
	export let label = undefined; 
	export let required = false; 

	let hoveredIndex = -1; 
    let isMob = false;

    onMount(() => { 
        isMob = window.innerWidth < 768;
    })

</script>

{#if label}
	<div class="block text-sm font-medium pb-1">
		{label}
		{#if required}<span class="text-red-500">*</span>{/if}
	</div>
{/if}
<div class="flex flex-col gap-0">
	{#each choices as choice, i (choice.value)}
		<div
			class="cursor-pointer p-2 border-l border-r border-t border-b border-gray-200 shadow-sm hover:text-3xl select-none"
			class:bg-gray-50={value === choice.value}
			class:border-t-0={i !== 0}
			class:border-b-0={i !== choices.length - 1}
			class:rounded-t-xl={i == 0}
			class:rounded-b-xl={i === choices.length - 1}
			on:mouseenter={() => (hoveredIndex = i)}
			on:mouseleave={() => hoveredIndex === i && (hoveredIndex = -1)}
			on:click={() => (value = choice.value)}
		>
			<div class="flex flex-row items-center justify-between p-1">
				<div class="flex flex-row items-center gap-2">
					<div class:text-xl={isMob || hoveredIndex !== i} class:text-2xl={!isMob && hoveredIndex === i}>
						{choice.emoji}
					</div>
					<div class="text-sm">
						{choice.label}
					</div>
				</div>
				<div class="text-lg">
					{#if value === choice.value}
						<CircleCheckIcon class="h-6 w-6 text-green-500" />
					{/if}
				</div>
			</div>
		</div>
	{/each}
</div>